import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, subtract, find, sumBy, divide, multiply, add, filter } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import { setHeading } from "../../../accounts/account.actions";
import { getArea } from "../AddArea/Area.action";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import { getProductData } from "../AddProduct/Product.action";
import { getShop, getShopByArea } from "../AddShop/Shop.action";
import { addOrder, GetProductWithSalesMenIDAPI } from "./OrderBooking.action";
import moment from "moment";
import "../add-details.css";
import { Switch } from "@mui/material";
import { getUserRes } from "../../AddUser/user.actions";
import { getCustomerData } from "../AddCustomer/Customer.action";


const AddOrderBooking = (props) => {
  const [areaName, setAreaName] = useState("");
  const [areaId, setAreaId] = useState();
  const [check, setCheck] = useState(false);
  const [shopName, setShopName] = useState("");
  const [bookerId, setBookerId] = useState("");
  const [bookerName, setBookerName] = useState("");
  const [shopId, setShopId] = useState();

  const [totalDiscount, setTotalDiscount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [grandTotal, setGrandTotal] = useState();
  const [subTotal, setSubTotal] = useState();
  const [tax, setTax] = useState(0);
  const [levelBalance, setLevelBalance] = useState(0);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [filteredShopList, setFilteredShopList] = useState([]);
  const [s1, setS1] = useState("Price");
  const [salesMenId, setSalesMenId] = useState();
  const [salesMenName, setSalesMenName] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [inputField, setInputField] = useState([
    {
      productNameArabic: "",
      productLabel: "",
      quantity: "",
      productSellingPrice: 0,
      productTotalAmount: 0,
      code: "",
      avaliableQuantity: "",
      wholesalePrice: 0,
    },
  ]);


  function uploadOrder() {
    props.addOrder(
      {
        areaId: areaId,
        shopId: shopId,
        subTotal: subTotal,
        discount: Number(totalDiscount),
        items: inputField,
        salesmenId: bookerId,
        levelBalance:levelBalance,
        // bookerId:bookerId,
        tax: tax,
        grandTotal: grandTotal,
        // date: date,
        orderType: 'Web',
        // dateShow: moment().format('llll'),
        paidAmount: Number(paidAmount),
      },
      handleVisible,
      handleDangerVisible
    );
    setAreaName("");
    setAreaId("");
    setShopName("");
    setShopId("");
    setBookerId("");
    setBookerName("");
    setGrandTotal(0);
    setSubTotal(0);
    setTax(0);
    setLevelBalance(0)
    setTotalDiscount(0);
    setPaidAmount(0)
    setInputField([
      {
        productNameArabic: "",
        productLabel: "",
        quantity: "",
        productSellingPrice: 0,
        productTotalAmount: 0,
        code: "",
        avaliableQuantity: "",
      },
    ]);
  }
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  const filterdUserList = !isEmpty(props.userList) && props.userList.filter((e) => e.role == "salesmen");
  const BookerList = !isEmpty(filterdUserList) && filterdUserList.map((x) => {
    let data = { value: x._id, label: x.userName };
    return data;
  });
  const handleChangeBooker = (selectedOption) => {
    setBookerId(selectedOption.value);
    setBookerName(selectedOption.label);
    props.GetProductWithSalesMenIDAPI({ salesMenId: selectedOption.value });
  };

  const handleChangeArea = (selectedOption) => {
    setAreaId(selectedOption.value);
    setAreaName(selectedOption.label);
    props.getShopByArea(selectedOption.value);
    handleCheck([
      {
        productNameArabic: "",
        productLabel: "",
        quantity: "",
        productSellingPrice: 0,
        productTotalAmount: 0,
        code: "",
        avaliableQuantity: "",
      },
    ])
  };
  const AreaListData = !isEmpty(props.areaDataList) && props.areaDataList.map((x) => {
    let data = { value: x._id, label: x.areaName };
    return data;
  });

  const handleChangeShop = (selectedOption) => {
    console.log(selectedOption,'selectedOption')
    setShopId(selectedOption.value);
    setShopName(selectedOption.label);
    setLevelBalance(selectedOption.levelBalance)
  };
  const ShopListData = !isEmpty(props.shopsByAreaList) && props.shopsByAreaList.map((x) => {
    let data = { value: x._id, label: x.shopName, levelBalance: x.levelBalance.closingBalance };
    return data;
  });

  const product = props.getProductWithSalesMenID.map((x) => {
    let data = {
      value: x.productId,
      label: `${x.code}-${x.name}`,
      code: x.code,
      avaliableQuantity: x.quantity,
    };
    return data;
  });
  const handleChange = (e, index, type) => {
    console.log(e, "tttttttttttttttttttttttt");
    const list = [...inputField];
    if (type == "intquantity")
      list[index]["quantity"] =
        Number(e.target.value) <= list[index]["avaliableQuantity"]
          ? Number(e.target.value)
          : list[index]["avaliableQuantity"];
    let z = find(props.productDataList, (x) => x._id == e.value);
    console.log(z, "zzzzzzzzzzzzzzzzz");
    if (type == "intname") {
      list[index]["product"] = e.value;
      list[index]["productLabel"] = e.label;
      list[index]["productNameArabic"] = z.productNameArabic;
      list[index]["code"] = e.code;
      list[index]["avaliableQuantity"] = e.avaliableQuantity;
      list[index]["quantity"] = 1;
    }
    if (type == "intname")
      list[index]["purchasePrice"] = Number(z.purchasePrice);
    if (type == "intname")
      list[index]["wholesalePrice"] = Number(z.wholesalePrice);

    if (type == "intname")
      list[index]["productSellingPrice"] = Number(z.salePrice);
    if (type == "productSellingPrice")
      list[index]["productSellingPrice"] = Number(e.target.value);

    list[index]["productTotalAmount"] =
      list[index]["productSellingPrice"] * list[index]["quantity"];

    setSubTotal(sumBy(list, "productTotalAmount"));
    setGrandTotal(sumBy(list, "productTotalAmount") - totalDiscount + (sumBy(list, "productTotalAmount") * 15) / 100+levelBalance);
    setTax((sumBy(list, "productTotalAmount") * 15) / 100)
    setInputField(list);
    handleCheck(list)
  };
  const handleAddInput = () => {
    setInputField([
      ...inputField,
      {
        productNameArabic: "",
        code: "",
        avaliableQuantity: "",
        productLabel: "",
        quantity: "",
        productSellingPrice: 0,
        productTotalAmount: 0,
        wholesalePrice: 0,
      },
    ]);
    handleCheck([
      ...inputField,
      {
        productNameArabic: "",
        code: "",
        avaliableQuantity: "",
        productLabel: "",
        quantity: "",
        productSellingPrice: 0,
        productTotalAmount: 0,
        wholesalePrice: 0,
      },
    ])
  };
  const handleCheck = (data) => {
    // console.log(data, "sssssssssssss")
    // // setCheck(false)
    // data.map((x) => {
    //   setCheck(false)
    //   if (x.productLabel != "") {
    //     setCheck(true)
    //   }
    //   // else{
    //   //  setCheck(false)
    //   // }
    // })
    const test = filter(data, { "productLabel": "" })
    console.log(test, "testtest")
    isEmpty(test) ? setCheck(true) : setCheck(false)
  };
  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
    setSubTotal(
      // list.length === 1
      //   ? list[0].productTotalAmount:
      sumBy(list, "productTotalAmount")
    );
    setGrandTotal(
      // list.length === 1
      // ? list[0].productTotalAmount
      // :
      sumBy(list, "productTotalAmount") - totalDiscount + (sumBy(list, "productTotalAmount") * 15) / 100+levelBalance
    );
    setTax((sumBy(list, "productTotalAmount") * 15) / 100)
    handleCheck(list)
  };


  useEffect(() => {
    props.setHeading("Add Order Booking");
    props.getArea();
    props.getProductData();
    props.getUser()
    // props.getCustomerData();
  }, []);
  return (
    <>
      {props.isFetchingAreaData ||
        props.isFetchingProduct ||
        props.isFetchingShopsByArea || props.isFetchingUser ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={date}
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    onChange={(e) => setDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Area *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(props.areaDataList)}
                    value={{ label: areaName }}
                    placeholder="Select Area..."
                    onChange={(e) => {
                      handleChangeArea(e);
                    }}
                    options={AreaListData}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Shop *</b>
                  </Form.Label>
                  <Select
                    isDisabled={
                      isEmpty(props.shopsByAreaList) || isEmpty(areaName)
                    }
                    placeholder="Select Shop..."
                    onChange={handleChangeShop}
                    options={ShopListData}
                  />
                </Form.Group>
              </Col>
              <Col style={{ zIndex: 5 }}>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Salesmen *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(shopName)}
                    value={{ label: bookerName }}
                    placeholder="Select Salesmen..."
                    onChange={handleChangeBooker}
                    options={BookerList}
                  />
                </Form.Group>
              </Col>
            </Row>

            {inputField.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="3" lg="3" md="3" sm="3">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Product *</b>
                        </Form.Label>
                        <Select
                          value={{ label: item.productLabel }}
                          // id="inventoryId"
                          placeholder="Select Product..."
                          // name="inventoryId"
                          isDisabled={isEmpty(bookerId)}
                          onChange={(e) => handleChange(e, i, "intname")}
                          options={product}
                          isOptionDisabled={(option) => !isEmpty(find(inputField, (x) => x.product == option.value))}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="2" lg="2" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Avaliable Quantity </b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          disabled
                          placeholder="Quantity...."
                          name="Quantity"
                          value={item.avaliableQuantity}
                          min={0}
                          onChange={(e) => handleChange(e, i, "intquantity")}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="2" lg="2" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          disabled={isEmpty(shopName)}
                          placeholder="Quantity...."
                          name="Quantity"
                          value={item.quantity}
                          min={0}
                          onChange={(e) => handleChange(e, i, "intquantity")}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="2" lg="2" md="6" sm="6">
                      <Form.Group>
                        <Form.Label>
                          <b>Price </b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          // disabled
                          disabled={s1 == "WholeSalePrice" ? true : true}
                          placeholder="Product Price"
                          name="Product Price"
                          value={item.productSellingPrice}
                          min={0}
                          onChange={(e) =>
                            handleChange(e, i, "productSellingPrice")
                          }
                        />
                      </Form.Group>
                    </Col>
                    {/* <Col xl="2" lg="2" md="6" sm="6">
                      <Row>
                        <Col xl="6" lg="6" md="6" sm="6">
                          <Form.Group>
                            <Form.Label>
                              <b>Price </b>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              // disabled
                              disabled={s1 == "WholeSalePrice" ? true : true}
                              placeholder="Product Price"
                              name="Product Price"
                              value={item.productSellingPrice}
                              min={0}
                              onChange={(e) =>
                                handleChange(e, i, "productSellingPrice")
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col
                          lg="6"
                          md="6"
                          xs="6"
                          className="mt-1"
                        // style={{ marginTop: "20%" }}
                        >
                          <Switch onClick={handleSwitch} />
                        </Col>
                      </Row>
                    </Col> */}

                    {/* <Col xl="1" lg="1" md="2" sm="4">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>WS Price</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          disabled={s1 == "Price" ? true : true}
                          placeholder="Wholesale Price...."
                          name="Quantity"
                        // value={item.quantity}
                        // min={0}
                        // onChange={(e) => handleChange(e, i, "intquantity")}
                        />
                      </Form.Group>
                    </Col> */}
                    <Col xl="2" lg="2" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Total Price</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Total Price...."
                          name="TotalPrice"
                          disabled
                          value={item.productTotalAmount}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField.length - 1 == i && (
                          <Button
                            title="Click to Add Product"
                            onClick={handleAddInput}
                            style={{
                              marginRight: "1%",
                              color: "white",
                              float: "right",
                            }}
                          >
                            <AddIcon />
                          </Button>
                        )}

                        {inputField.length !== 1 && (
                          <Button
                            title="Click to Remove Product"
                            onClick={() => handleRemoveInput(i)}
                            style={{
                              marginRight: "1%",
                              color: "white",
                              float: "right",
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}
            <Row>
              <Col>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Sub Total </b>
                  </Form.Label>
                  <Form.Control
                    value={subTotal}
                    disabled
                    type="number"
                  // onChange={(e) => {
                  //   setSubTotal(e.target.value);
                  // }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Discount</b>
                  </Form.Label>
                  <Form.Control
                    value={totalDiscount}
                    disabled={inputField[0].productLabel == ""}
                    type="number"
                    min={0}
                    max={subTotal}
                    onChange={(e) => {
                      e.target.value < subTotal &&
                        setTotalDiscount(e.target.value);

                      e.target.value < subTotal &&
                        setGrandTotal(subTotal - e.target.value + ((subTotal - e.target.value) * 15) / 100+levelBalance);
                      setTax(((subTotal - e.target.value) * 15) / 100)
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label><b>Tax (15%)</b></Form.Label>
                  <Form.Control
                    value={tax}
                    min={0}
                    disabled
                    type="number"
                  // onChange={(e) => { setPaidAmount(e.target.value); }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label><b>Outstanding Balance</b></Form.Label>
                  <Form.Control
                    value={levelBalance}
                    min={0}
                    disabled
                    type="number"
                  // onChange={(e) => { setPaidAmount(e.target.value); }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Grand Total</b>
                  </Form.Label>
                  <Form.Control
                    value={grandTotal}
                    disabled
                    type="number"
                  // onChange={(e) => {
                  //   setGrandTotal(e.target.value);
                  // }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label><b>Paid Amount</b></Form.Label>
                  <Form.Control
                    value={paidAmount}
                    min={0}
                    disabled={inputField[0].productLabel == ""}
                    type="number"
                    onChange={(e) => { setPaidAmount(e.target.value); }}
                  />
                </Form.Group>
              </Col>
           
            </Row>
            {props.isAddingOrderBooking ? (
              <div className="loader-div">
                <Spinner
                  variant="primary"
                  animation="grow"
                  size="xl"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <div className="sendDiv">
                {isEmpty(areaId) ||
                  isEmpty(shopId) || salesMenId == "" ||

                  check == false ? (
                  <Button disabled>Save</Button>
                ) : (
                  <Button onClick={uploadOrder}>Save</Button>
                )}
              </div>
            )}
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getArea: () => dispatch(getArea()),
    getShop: () => dispatch(getShop()),
    getCustomerData: () => dispatch(getCustomerData()),
    addOrder: (data, handleVisible, handleDangerVisible) => dispatch(addOrder(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
    getProductData: () => dispatch(getProductData()),
    getShopByArea: (id) => dispatch(getShopByArea(id)),
    GetProductWithSalesMenIDAPI: (data) => dispatch(GetProductWithSalesMenIDAPI(data)),
    getUser: () => dispatch(getUserRes())
  };
};
const mapStateToProps = (state, ownProps) => ({
  orderBookingList: state.OrderBookingReducer.orderBookingList,
  isAddingOrderBooking: state.OrderBookingReducer.isAddingOrderBooking,
  shopsByAreaList: state.ShopReducer.shopsByAreaList,
  isFetchingShopsByArea: state.ShopReducer.isFetchingShopsByArea,
  shopDataList: state.ShopReducer.shopDataList,
  isFetchingShopData: state.ShopReducer.isFetchingShopData,
  areaDataList: state.AreaReducer.areaDataList,
  isFetchingAreaData: state.AreaReducer.isFetchingAreaData,
  productDataList: state.ProductReducer.productDataList,
  isFetchingProduct: state.ProductReducer.isFetchingProduct,
  isFetchingUser: state.UserReducer.isFetchingUser,
  userList: state.UserReducer.userList,
  customerDataList: state.CustomerReducer.customerDataList,
  isFetchingCustomer: state.CustomerReducer.isFetchingCustomer,

  getProductWithSalesMenID: state.OrderBookingReducer.getProductWithSalesMenID,
  isFetchingProductWithSalesMenID: state.OrderBookingReducer.isFetchingProductWithSalesMenID,
});
export default connect(mapStateToProps, mapDispatchToProps)(AddOrderBooking);
