import React, { useEffect, useState } from "react";
import { Row, Col, Form, Table, Container, Button, FormGroup, Alert, Tab, Tabs } from "react-bootstrap";
import { ModalBody, Modal } from "reactstrap";
// import {
//     getPurchase, getApproveDemand, addIssuance, getAllInventory, getIssuance, searchIssuanceReport
// } from "../AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
// import WareHouseModal from "../WareHouseModal";
// import MaterialTable from 'material-table';
// import AddIcon from "@material-ui/icons/Add";
// import RemoveIcon from "@material-ui/icons/Remove";
// import { IconButton } from "@material-ui/core";
import Select from 'react-select';
import Swal from 'sweetalert';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import moment from "moment";
import { addDayClosing, addDayClosingBySalemen, getDayOpening, setEditFields } from "./Day.action";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconMenu from "../../../drawer/IconMenu";
import { setHeading } from "../../../accounts/account.actions";
const ClosingDay = (props) => {

    const [cashReceived, setCashReceived] = useState(0);
    const [cashReceivedArray, setCashReceivedArray] = useState();
    const [opening, setOpening] = useState(
        {
            id: '',
            label: '',
            openingCash: 0,
            sumCancelAmount: 0,
            sumDiscountAmount: 0,
            sumOrderAmount: 0,
            totalCash: 0,

        }
    );
    console.log(props.dayOpeningList, "hmhmhm")
    // const openingList = !isEmpty(props.dayOpeningList) ? props.dayOpeningList.data.map((x) => { return { value: x._id, label: `${x.dayOpenDate} ${x.dayOpenName}`,openingCash: x.cashInHand,sumCancelAmount:x.sumCancelAmount,sumDiscountAmount:x.sumDiscountAmount,sumOrderAmount:x.sumOrderAmount,totalCash:x.totalCash }; }) : [];
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const [userClick, setUserClick] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleVisible = (msg) => {
        props.getDayOpening()
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    }
    const handleChangeOpening = (e, i) => {
        const list = [...props.dayOpeningList]
        list[i]['receivedAmount'] = e
        // // setCashReceivedArray()
        console.log('selftesttttttttttttttttt', list)
        setEditFields(list)
        setCashReceivedArray(list)
    }
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    }
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    function Logout() {
        window.localStorage.clear();
        window.location.replace("/login");
    }
    function uploadPurchase(item) {
        console.log(item, 'aaaaaaaaaaaaaaaaa')
        props.addDayClosingBySalemen({
            salemenId: item.salesmenId._id,
            salemenName: item.salesmenId.userName,
            subTotal: item.subTotal,
            discount: item.discount,
            tax: item.tax,
            grandTotal: item.grandTotal,
            receivedAmount: Number(item.receivedAmount),
            paidAmount: item.paidAmount,
            shortAmount: item.paidAmount - Number(item.receivedAmount)
        }, handleVisible, handleDangerVisible)
        // props.addDayClosing({ cash: cash, dayOpenId: props.dayOpeningList.data._id }, handleVisible, handleDangerVisible)
        // setCash('');
        // setOpening({
        //     id: '',
        //     label: '',
        //     openingCash: 0,
        //     sumCancelAmount: 0,
        //     sumDiscountAmount: 0,
        //     sumOrderAmount: 0,
        //     totalCash: 0,

        // })
    }
    function dayClose() {
        // props.addDayClosingBySalemen({
        //     salemenId: item.salesmenId._id,
        //     salemenName: item.salesmenId.userName,
        //     subTotal: item.subTotal,
        //     discount: item.discount,
        //     tax: item.tax,
        //     grandTotal: item.grandTotal,
        //     receivedAmount: Number(item.receivedAmount),
        //     paidAmount: item.paidAmount,
        //     shortAmount: item.paidAmount - Number(item.receivedAmount)
        // }, handleVisible, handleDangerVisible)
        props.addDayClosing({}, handleVisible, handleDangerVisible)
        // setCash('');
        // setOpening({
        //     id: '',
        //     label: '',
        //     openingCash: 0,
        //     sumCancelAmount: 0,
        //     sumDiscountAmount: 0,
        //     sumOrderAmount: 0,
        //     totalCash: 0,

        // })
    }
    useEffect(() => {
        props.getDayOpening();
        props.setHeading("Day Close")
    }, []);

    return (
        <>

            {props.isGettingDayOpening ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isAddingIssuance ? 'Saving...' : 'Loading...'}

                    </Button>
                </div>
            ) :
                (
                    <Container fluid>
                        {console.log(cashReceivedArray, 'oooooooooooooooooooo')}
                        {isEmpty(props.dayOpeningList) ?
                            <b>No Order Found</b>
                            :
                            <>
                                {props.dayOpeningList.map((item, i) => {
                                    console.log(item, 'iiiiiiiiiiiiiiii')
                                    return (
                                        <React.Fragment key={i}>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label><b>SalesMen Name</b></Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            placeholder="SalesMen Name"
                                                            value={item.salesmenId.userName}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label><b>Sub Total</b></Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            placeholder="Sub Total"
                                                            value={item.subTotal}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label><b>Discount</b></Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            placeholder="Discount"
                                                            value={item.discount}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label><b>Grand Total </b></Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            placeholder="Grand Total"
                                                            value={item.grandTotal}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label><b>Paid Amount</b></Form.Label>
                                                        <Form.Control
                                                            placeholder="Paid Amount"
                                                            disabled
                                                            value={item.paidAmount}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label><b>Cash Received</b></Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Cash Received"
                                                            value={item.receivedAmount}
                                                            onChange={(e) => handleChangeOpening(e.target.value, i)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col style={{ marginTop: '28px' }}>
                                                    <Button onClick={() => uploadPurchase(item)}>Save</Button>
                                                </Col>

                                            </Row>

                                        </React.Fragment>
                                    );
                                })}
                                <Row>
                                    <Col xl="4" lg="4" md="4" sm="4"></Col>
                                    <Col xl="4" lg="4" md="4" sm="4">
                                        <Button onClick={() => dayClose()}>Close Today</Button>
                                    </Col>
                                    <Col xl="4" lg="4" md="4" sm="4"></Col>
                                </Row>
                            </>
                        }
                    </Container>
                )
            }
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDayOpening: () => dispatch(getDayOpening()),
        setEditFields: (list) => dispatch(setEditFields(list)),
        addDayClosing: (data, handleVisible, handleDangerVisible) => dispatch(addDayClosing(data, handleVisible, handleDangerVisible)),
        addDayClosingBySalemen: (data, handleVisible, handleDangerVisible) => dispatch(addDayClosingBySalemen(data, handleVisible, handleDangerVisible)),
        setHeading: (data) => dispatch(setHeading(data))
    };
};
const mapStateToProps = (state, ownProps) => ({

    isGettingDayOpening: state.DayReducer.isGettingDayOpening,
    dayOpeningList: state.DayReducer.dayOpeningList,

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClosingDay);